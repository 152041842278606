import { createRouter, createWebHistory } from 'vue-router'

//import Home from '../views/Home.vue'



const routes = [

  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/test',
    name: 'test',
    meta: { title: '测试' },
    component: () => import('../views/About.vue'),
  },
  //双色球-----------------------------------------
  {
    path: '/ssq',
    name: 'ssq',
    meta: { title: '双色球' },
    component: () => import('../components/ssq/ssq.vue'),
    redirect: '/red-basic',
    children: [
      {
        path: '/red-basic',
        name: 'red-basic',
        meta: { title: '基本走势图' },
        component: () => import('../components/ssq/red-basic.vue')
      },
      {
        path: '/red-tail',
        name: 'red-tail',
        meta: { title: '红球走势图' },
        component: () => import('../components/ssq/red-tail.vue')
      },
      {
        path: '/red-complex',
        name: 'red-complex',
        meta: { title: '综合走势图' },
        component: () => import('../components/ssq/red-complex.vue')
      },
      {
        path: '/red-he',
        name: 'red-he',
        meta: { title: '合值走势图' },
        component: () => import('../components/ssq/red-he.vue')
      },
      {
        path: '/red-ac',
        name: 'red-ac',
        meta: { title: 'AC值走势图' },
        component: () => import('../components/ssq/red-ac.vue')
      },
      {
        path: '/red1',
        name: 'red1',
        meta: { title: '红球第一位走势图' },
        component: () => import('../components/ssq/red1.vue')
      },
      {
        path: '/red2',
        name: 'red2',
        meta: { title: '红球第二位走势图' },
        component: () => import('../components/ssq/red2.vue')
      },
      {
        path: '/red3',
        name: 'red3',
        meta: { title: '红球第三位走势图' },
        component: () => import('../components/ssq/red3.vue')
      },
      {
        path: '/red4',
        name: 'red4',
        meta: { title: '红球第四位走势图' },
        component: () => import('../components/ssq/red4.vue')
      },
      {
        path: '/red5',
        name: 'red5',
        meta: { title: '红球第五位走势图' },
        component: () => import('../components/ssq/red5.vue')
      },
      {
        path: '/red6',
        name: 'red6',
        meta: { title: '红球第六位走势图' },
        component: () => import('../components/ssq/red6.vue')
      },
      {
        path: '/tuesday',
        name: 'tuesday',
        meta: { title: '周二走势图' },
        component: () => import('../components/ssq/tuesday.vue')
      },
      {
        path: '/thursday',
        name: 'thursday',
        meta: { title: '周四走势图' },
        component: () => import('../components/ssq/thursday.vue')
      },
      {
        path: '/sunday',
        name: 'sunday',
        meta: { title: '周日走势图' },
        component: () => import('../components/ssq/sunday.vue')
      },
      {
        path: '/lan-basic',
        name: 'lan-basic',
        meta: { title: '蓝球走势图' },
        component: () => import('../components/ssq/lan-basic.vue')
      },
      {
        path: '/lan-tail',
        name: '/lan-tail',
        meta: { title: '蓝球尾数走势图' },
        component: () => import('../components/ssq/lan-tail.vue')
      },
    ]
  },
  //快乐8-------------------------------------
  {
    path: '/klb',
    name: 'klb',
    component: () => import('../components/klb/klb.vue'),
    redirect: '/klb-basic',
    children: [
      {
        path: '/klb-basic',
        name: 'klb-basic',
        component: () => import('../components/klb/klb-basic.vue')
      },
      {
        path: '/klb-tail',
        name: 'klb-tail',
        component: () => import('../components/klb/klb-tail.vue')
      },
      {
        path: '/klb-he',
        name: 'klb-he',
        component: () => import('../components/klb/klb-he.vue')
      },
      {
        path: '/klb-ac',
        name: 'klb-ac',
        component: () => import('../components/klb/klb-ac.vue')
      },
      {
        path: '/klb-kua',
        name: 'klb-kua',
        component: () => import('../components/klb/klb-kua.vue')
      },
      {
        path: '/klb-q1',
        name: 'klb-q1',
        component: () => import('../components/klb/klb-q1.vue')
      },
      {
        path: '/klb-q2',
        name: 'klb-q2',
        component: () => import('../components/klb/klb-q2.vue')
      },
      {
        path: '/klb-q3',
        name: 'klb-q3',
        component: () => import('../components/klb/klb-q3.vue')
      },
      {
        path: '/klb-q4',
        name: 'klb-q4',
        component: () => import('../components/klb/klb-q4.vue')
      },
      {
        path: '/klb-q5',
        name: 'klb-q5',
        component: () => import('../components/klb/klb-q5.vue')
      },
      {
        path: '/klb-q6',
        name: 'klb-q6',
        component: () => import('../components/klb/klb-q6.vue')
      },
      {
        path: '/klb-q7',
        name: 'klb-q7',
        component: () => import('../components/klb/klb-q7.vue')
      },
      {
        path: '/klb-q8',
        name: 'klb-q8',
        component: () => import('../components/klb/klb-q8.vue')
      },
    ]
  },
  // 福彩3D--------------------------------------
  {
    path: '/3d',
    name: '3d',
    component: () => import('../components/3d/3d.vue'),
    redirect: '/3d-basic',
    children: [
      {
        path: '/3d-basic',
        name: '3d-basic',
        component: () => import('../components/3d/3d-basic.vue')
      },
      {
        path: '/3d-tail',
        name: '3d-tail',
        component: () => import('../components/3d/3d-tail.vue')
      },
      {
        path: '/3d-bai',
        name: '3d-bai',
        component: () => import('../components/3d/3d-bai.vue')
      },
      {
        path: '/3d-shi',
        name: '3d-shi',
        component: () => import('../components/3d/3d-shi.vue')
      },
      {
        path: '/3d-ge',
        name: '3d-ge',
        component: () => import('../components/3d/3d-ge.vue')
      },
      {
        path: '/3d-hew',
        name: '3d-hew',
        component: () => import('../components/3d/3d-hew.vue')
      },
      {
        path: '/3d-4ma',
        name: '3d-4ma',
        component: () => import('../components/3d/3d-4ma.vue')
      },
      {
        path: '/3d-5ma',
        name: '3d-5ma',
        component: () => import('../components/3d/3d-5ma.vue')
      },
      {
        path: '/3d-6ma',
        name: '3d-6ma',
        component: () => import('../components/3d/3d-6ma.vue')
      },
      {
        path: '/3d-7ma',
        name: '3d-7ma',
        component: () => import('../components/3d/3d-7ma.vue')
      },
      {
        path: '/3d-kua',
        name: '3d-kua',
        component: () => import('../components/3d/3d-kua.vue')
      },
      {
        path: '/3d-baij',
        name: '3d-baij',
        component: () => import('../components/3d/3d-baij.vue')
      },
      {
        path: '/3d-shij',
        name: '3d-shij',
        component: () => import('../components/3d/3d-shij.vue')
      },
      {
        path: '/3d-gej',
        name: '3d-gej',
        component: () => import('../components/3d/3d-gej.vue')
      },
      {
        path: '/3d-hej',
        name: '3d-hej',
        component: () => import('../components/3d/3d-hej.vue')
      },



    ]
  },
  // 七乐彩--------------------------------------
  {
    path: '/qlc',
    name: 'qlc',
    component: () => import('../components/qlc/qlc.vue'),
    redirect: '/qlc-basic',
    children: [
      {
        path: '/qlc-basic',
        name: 'qlc-basic',
        component: () => import('../components/qlc/qlc-basic.vue')
      },
      {
        path: '/qlc-q1',
        name: 'qlc-q1',
        component: () => import('../components/qlc/qlc-q1.vue')
      },
      {
        path: '/qlc-q2',
        name: 'qlc-q2',
        component: () => import('../components/qlc/qlc-q2.vue')
      },
      {
        path: '/qlc-q3',
        name: 'qlc-q3',
        component: () => import('../components/qlc/qlc-q3.vue')
      },
      {
        path: '/qlc-hew',
        name: 'qlc-hew',
        component: () => import('../components/qlc/qlc-hew.vue')
      },
      {
        path: '/qlc-kua',
        name: 'qlc-kua',
        component: () => import('../components/qlc/qlc-kua.vue')
      },
      {
        path: '/qlc-ac',
        name: 'qlc-ac',
        component: () => import('../components/qlc/qlc-ac.vue')
      },
    ]
  },
  // 大乐透--------------------------------------
  {
    path: '/dlt',
    name: 'dlt',
    component: () => import('../components/dlt/dlt.vue'),
    redirect: '/dlt-basic',
    children: [
      {
        path: '/dlt-basic',
        name: 'dlt-basic',
        component: () => import('../components/dlt/dlt-basic.vue')
      },
      {
        path: '/dlt-tail',
        name: 'dlt-tail',
        component: () => import('../components/dlt/dlt-tail.vue')
      },
      {
        path: '/dlt-kua',
        name: 'dlt-kua',
        component: () => import('../components/dlt/dlt-kua.vue')
      },
      {
        path: '/dlt-he',
        name: 'dlt-he',
        component: () => import('../components/dlt/dlt-he.vue')
      },
      {
        path: '/dlt-ac',
        name: 'dlt-ac',
        component: () => import('../components/dlt/dlt-ac.vue')
      },
      {
        path: '/dlt-1',
        name: 'dlt-1',
        component: () => import('../components/dlt/dlt-1.vue')
      },
      {
        path: '/dlt-2',
        name: 'dlt-2',
        component: () => import('../components/dlt/dlt-2.vue')
      },
      {
        path: '/dlt-3',
        name: 'dlt-3',
        component: () => import('../components/dlt/dlt-3.vue')
      },
      {
        path: '/dlt-4',
        name: 'dlt-4',
        component: () => import('../components/dlt/dlt-4.vue')
      },
      {
        path: '/dlt-5',
        name: 'dlt-5',
        component: () => import('../components/dlt/dlt-5.vue')
      },
      {
        path: '/dlt-monday',
        name: 'dlt-monday',
        component: () => import('../components/dlt/dlt-monday.vue')
      },
      {
        path: '/dlt-wednesday',
        name: 'dlt-wednesday',
        component: () => import('../components/dlt/dlt-wednesday.vue')
      },
      {
        path: '/dlt-saturday',
        name: 'dlt-saturday',
        component: () => import('../components/dlt/dlt-saturday.vue')
      },
      {
        path: '/dlth-basic',
        name: 'dlth-basic',
        component: () => import('../components/dlt/dlth-basic.vue')
      },
      {
        path: '/dltl-tail',
        name: 'dltl-tail',
        component: () => import('../components/dlt/dltl-tail.vue')
      },
      {
        path: '/dlt-q1',
        name: 'dlt-q1',
        component: () => import('../components/dlt/dlt-q1.vue')
      },
      {
        path: '/dlt-q2',
        name: 'dlt-q2',
        component: () => import('../components/dlt/dlt-q2.vue')
      },
      {
        path: '/dlt-q3',
        name: 'dlt-q3',
        component: () => import('../components/dlt/dlt-q3.vue')
      },


    ]
  },
  // 排列三--------------------------------------
  {
    path: '/pl35',
    name: 'pl35',
    component: () => import('../components/pl35/pl35.vue'),
    redirect: '/pl35-basic',
    children: [
      {
        path: '/pl35-basic',
        name: 'pl35-basic',
        component: () => import('../components/pl35/pl35-basic.vue')
      },
      {
        path: '/pl35-tail',
        name: 'pl35-tail',
        component: () => import('../components/pl35/pl35-tail.vue')
      },
      {
        path: '/pl35-bai',
        name: 'pl35-bai',
        component: () => import('../components/pl35/pl35-bai.vue')
      },
      {
        path: '/pl35-shi',
        name: 'pl35-shi',
        component: () => import('../components/pl35/pl35-shi.vue')
      },
      {
        path: '/pl35-ge',
        name: 'pl35-ge',
        component: () => import('../components/pl35/pl35-ge.vue')
      },
      {
        path: '/pl35-hew',
        name: 'pl35-hew',
        component: () => import('../components/pl35/pl35-hew.vue')
      },
      {
        path: '/pl35-4ma',
        name: 'pl35-4ma',
        component: () => import('../components/pl35/pl35-4ma.vue')
      },
      {
        path: '/pl35-5ma',
        name: 'pl35-5ma',
        component: () => import('../components/pl35/pl35-5ma.vue')
      },
      {
        path: '/pl35-6ma',
        name: 'pl35-6ma',
        component: () => import('../components/pl35/pl35-6ma.vue')
      },
      {
        path: '/pl35-7ma',
        name: 'pl35-7ma',
        component: () => import('../components/pl35/pl35-7ma.vue')
      },
      {
        path: '/pl35-kua',
        name: 'pl35-kua',
        component: () => import('../components/pl35/pl35-kua.vue')
      },
      {
        path: '/pl35-baij',
        name: 'pl35-baij',
        component: () => import('../components/pl35/pl35-baij.vue')
      },
      {
        path: '/pl35-shij',
        name: 'pl35-shij',
        component: () => import('../components/pl35/pl35-shij.vue')
      },
      {
        path: '/pl35-gej',
        name: 'pl35-gej',
        component: () => import('../components/pl35/pl35-gej.vue')
      },
      {
        path: '/pl35-hej',
        name: 'pl35-hej',
        component: () => import('../components/pl35/pl35-hej.vue')
      },

      {
        path: '/pl5-basic',
        name: 'pl5-basic',
        component: () => import('../components/pl35/pl5-basic.vue')
      },
      {
        path: '/pl5-shi',
        name: 'pl5-shi',
        component: () => import('../components/pl35/pl5-shi.vue')
      },
      {
        path: '/pl5-ge',
        name: 'pl5-ge',
        component: () => import('../components/pl35/pl5-ge.vue')
      },

    ]

  },
  // 七星彩--------------------------------------
  {
    path: '/qxc',
    name: 'qxc',
    component: () => import('../components/qxc/qxc.vue'),
    redirect: '/qxc-basic',
    children: [
      {
        path: '/qxc-basic',
        name: 'qxc-basic',
        component: () => import('../components/qxc/qxc-basic.vue')
      },
      {
        path: '/qxc-q1',
        name: 'qxc-q1',
        component: () => import('../components/qxc/qxc-q1.vue')
      },
      {
        path: '/qxc-q2',
        name: 'qxc-q2',
        component: () => import('../components/qxc/qxc-q2.vue')
      },
      {
        path: '/qxc-q3',
        name: 'qxc-q3',
        component: () => import('../components/qxc/qxc-q3.vue')
      },
      {
        path: '/qxc-q4',
        name: 'qxc-q4',
        component: () => import('../components/qxc/qxc-q4.vue')
      },
      {
        path: '/qxc-q5',
        name: 'qxc-q5',
        component: () => import('../components/qxc/qxc-q5.vue')
      },
      {
        path: '/qxc-q6',
        name: 'qxc-q6',
        component: () => import('../components/qxc/qxc-q6.vue')
      },
      {
        path: '/qxc-q7',
        name: 'qxc-q7',
        component: () => import('../components/qxc/qxc-q7.vue')
      },
      {
        path: '/qxc-hew',
        name: 'qxc-hew',
        component: () => import('../components/qxc/qxc-hew.vue')
      },
      {
        path: '/qxc-kua',
        name: 'qxc-kua',
        component: () => import('../components/qxc/qxc-kua.vue')
      },
      {
        path: '/qxc-ac',
        name: 'qxc-ac',
        component: () => import('../components/qxc/qxc-ac.vue')
      },
    ]
  },
  //选号工具--------------------------------------
  {
    path: '/Number-select',
    name: 'Number-select',
    component: () => import('../components/Number-select/Number-select.vue'),
    redirect: '/ssq-number',
    children: [
      {
        path: '/ssq-number',
        name: 'ssq-number',
        component: () => import('../components/Number-select/ssq-num/ssq-number.vue')
      },
      {
        path: '/klb-number',
        name: 'klb-number',
        component: () => import('../components/Number-select/klb-num/klb-number.vue')
      },
      {
        path: '/sd-number',
        name: 'sd-number',
        component: () => import('../components/Number-select/sd-num/sd-number.vue')
      },
      {
        path: '/qlc-number',
        name: 'qlc-number',
        component: () => import('../components/Number-select/qlc-num/qlc-number.vue')
      },
      {
        path: '/dlt-number',
        name: 'dlt-number',
        component: () => import('../components/Number-select/dlt-num/dlt-number.vue')
      },
      {
        path: '/pl35-number',
        name: 'pl35-number',
        component: () => import('../components/Number-select/pl35-num/pl35-number.vue')
      },
      {
        path: '/qxc-number',
        name: 'qxc-number',
        component: () => import('../components/Number-select/qxc-num/qxc-number.vue')
      },

    ]

  },

  //选号介绍--------------------------------------  
  {
    path: '/wf-index',
    name: 'wf-index',
    component: () => import('../components/wanfa/wf-index.vue'),
    redirect: '/wf-ssq',
    children: [
      {
        path: '/wf-ssq',
        name: 'wf-ssq',
        component: () => import('../components/wanfa/wf-ssq.vue')
      },
      {
        path: '/wf-klb',
        name: 'wf-klb',
        component: () => import('../components/wanfa/wf-klb.vue')
      },
      {
        path: '/wf-sd',
        name: 'wf-sd',
        component: () => import('../components/wanfa/wf-sd.vue')
      },
      {
        path: '/wf-qlc',
        name: 'wf-qlc',
        component: () => import('../components/wanfa/wf-qlc.vue')
      },
      {
        path: '/wf-dlt',
        name: 'wf-dlt',
        component: () => import('../components/wanfa/wf-dlt.vue')
      },
      {
        path: '/wf-pl35',
        name: 'wf-pl35',
        component: () => import('../components/wanfa/wf-pl35.vue')
      },
      {
        path: '/wf-qxc',
        name: 'wf-qxc',
        component: () => import('../components/wanfa/wf-qxc.vue')
      },
    ]

  },
  //奖金计算--------------------------------------  
  {
    path: '/jin-index',
    name: 'jin-index',
    component: () => import('../components/jin/jin-index.vue'),
    redirect: '/jin-ssq',
    children: [
      {
        path: '/jin-ssq',
        name: 'jin-ssq',
        component: () => import('../components/jin/jin-ssq.vue'),
      },
      {
        path: '/jin-klb',
        name: 'jin-klb',
        component: () => import('../components/jin/jin-klb.vue')
      },
      {
        path: '/jin-fc3d',
        name: 'jin-fc3d',
        component: () => import('../components/jin/jin-fc3d.vue')
      },
      {
        path: '/jin-qlc',
        name: 'jin-qlc',
        component: () => import('../components/jin/jin-qlc.vue')
      },
      {
        path: '/jin-dlt',
        name: 'jin-dlt',
        component: () => import('../components/jin/jin-dlt.vue')
      },
      {
        path: '/jin-pl35',
        name: 'jin-pl35',
        component: () => import('../components/jin/jin-pl35.vue')
      },
      {
        path: '/jin-qxc',
        name: 'jin-qxc',
        component: () => import('../components/jin/jin-qxc.vue')
      },
    ]
  },
  //--------------------------遗漏查询--------------------------------------  
  {
    path: '/yi-index',
    name: 'yi-index',
    component: () => import('../components/yilou/yi-index.vue'),
    redirect: '/yi-fc3d',
    children: [
      {
        path: '/yi-ssq',
        name: 'yi-ssq',
        component: () => import('../components/yilou/yi-ssq.vue'),
      },
      {
        path: '/yi-klb',
        name: 'yi-klb',
        component: () => import('../components/yilou/yi-klb.vue')
      },
      {
        path: '/yi-fc3d',
        name: 'yi-fc3d',
        component: () => import('../components/yilou/yi-fc3d.vue')
      },
      {
        path: '/yi-qlc',
        name: 'yi-qlc',
        component: () => import('../components/yilou/yi-qlc.vue')
      },
      {
        path: '/yi-dlt',
        name: 'yi-dlt',
        component: () => import('../components/yilou/yi-dlt.vue')
      },
      {
        path: '/yi-pl35',
        name: 'yi-pl35',
        component: () => import('../components/yilou/yi-pl35.vue')
      },
      {
        path: '/yi-qxc',
        name: 'yi-qxc',
        component: () => import('../components/yilou/yi-qxc.vue')
      },
    ]
  },
  //--------------------------号码预测--------------------------------------  
  {
    path: '/yc-index',
    name: 'yc-index',
    component: () => import('../components/yuce/yc-index.vue'),
    redirect: '/yc-ssq',
    children: [
      {
        path: '/yc-ssq',
        name: 'yc-ssq',
        component: () => import('../components/yuce/yc-ssq.vue'),
      },
      {
        path: '/yc-klb',
        name: 'yc-klb',
        component: () => import('../components/yuce/yc-klb.vue')
      },
      {
        path: '/yc-fc3d',
        name: 'yc-fc3d',
        component: () => import('../components/yuce/yc-fc3d.vue')
      },
      {
        path: '/yc-qlc',
        name: 'yc-qlc',
        component: () => import('../components/yuce/yc-qlc.vue')
      },
      {
        path: '/yc-dlt',
        name: 'yc-dlt',
        component: () => import('../components/yuce/yc-dlt.vue')
      },
      {
        path: '/yc-pl35',
        name: 'yc-pl35',
        component: () => import('../components/yuce/yc-pl35.vue')
      },
      {
        path: '/yc-qxc',
        name: 'yc-qxc',
        component: () => import('../components/yuce/yc-qxc.vue')
      },
    ]
  },
//--------------------------客服--------------------------------------  
{
  path: '/kf-index',
  name: 'kf-index',
  component: () => import('../components/kefu/kf-index.vue'),
},

]





const router = createRouter({
  history: createWebHistory(),
  routes,

})


export default router



