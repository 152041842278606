<template>
  <div id="app">
    <Top></Top>
    <Header></Header>
    <!-- <map></map> -->
  </div>
  <router-view />
</template>

<script>

  //import './js/font.js' //js文件引入，注意路径
  import Header from '@/components/Header.vue'
  import Top from '@/components/Top.vue'
  //import map from '@/components/Map.vue'
  export default {
    name: 'App',
    components: {
      Header,
      Top
    },

    // ------------------vuex刷新数据丢失问题解决---------------------------
    created() {
      // 在页面加载时读取sessionStorage
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
      }
      // 在页面刷新时将store保存到sessionStorage里
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      })

     

    },



  }


</script>

<style>
  @media only screen and (min-width:1960px) {
    html {
      font-size: 110% !important;
    }

    body {
      width: 1960px;
    }

  }

  @media only screen and (max-width:1960px) {
    html {
      font-size: 95% !important;
    }
  }

  @media only screen and (max-width:1680px) {
    html {
      font-size: 85% !important;
    }

  }

  @media only screen and (max-width:1440px) {
    html {
      font-size: 75% !important;
    }

  }

  @media only screen and (max-width:1366px) {
    html {
      font-size: 70% !important;
      width: 1366px
    }

  }


  /* @media only screen and (max-width:1024px){
	html{font-size:62.5%!important; }
 
} */


  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  * {
    margin: 1px 1px;
    padding: 1px 1px;
    box-sizing: border-box;
  }

  .rou_a {
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }

  ul li {
    list-style: none;
  }


  .clear {
    clear: both;
  }
</style>