<!-- 导航栏 -->
<template>
  <nav>
    <div class="header">
      <ul>
        <router-link :to="item.rou" v-for="(item, i) in list" :key="i">
          <li :class="{ active: i == head }" @click="one(i)">
            {{ item.title }}
          </li>
        </router-link>
        <div class="clear"></div>
      </ul>
      <div class="clear"></div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
//mport mitt from '@/js/mitt.js';
//import Introduction from '@/views/Introduction.vue'
export default {
  name: "Header",
  //  components: {
  //HelloWorld
  //Introduction
  // },
  data() {
    // 数据源
    return {
      list: [
        { title: "首页", rou: "/" },
        { title: "双色球", rou: "/ssq" },
        { title: "快乐8", rou: "/klb" },
        { title: "福彩3D", rou: "/3d" },
        { title: "七乐彩", rou: "/qlc" },
        { title: "大乐透", rou: "/dlt" },
        { title: "排列3/5", rou: "/pl35" },
        { title: "七星彩", rou: "/qxc" },
        { title: "投注表", rou: "/wf-index" },
        { title: "奖金计算器", rou: "/jin-index" },
        { title: "选号工具", rou: "/Number-select" },
        { title: "遗漏查询", rou: "/yi-index" },
        { title: "号码预测", rou: "/yc-index" },
        { title: "联系客服", rou: "/kf-index" },
      ],
      head: -1,
    };
  },
  mounted() {
    // mitt.$on('one', this.one)
     this.head = this.header;
  },
  computed: {
    ...mapState(["header"]),
  },
  methods: {
    // 函数体部分，js的主要逻辑控制
    ...mapMutations(["mutationheader"]),
    ...mapActions(["actionheader"]),
    one(i) {
      this.actionheader(i);
      sessionStorage.setItem("ssq", 0);
      sessionStorage.setItem("klb", 0);
      sessionStorage.setItem("fc3d", 0);
      sessionStorage.setItem("qlc", 0);
      sessionStorage.setItem("dlt", 0);
      sessionStorage.setItem("pl35", 0);
      sessionStorage.setItem("qxc", 0);
      sessionStorage.setItem("touzhu", 0);
      sessionStorage.setItem("jiangjin", 0);
      sessionStorage.setItem("xuanhao", 0);
      sessionStorage.setItem("yilou", 0);
    },
  },
  props: {},
  watch: {
    header() {
      this.head = this.header;
    },
    $route: {
      handler: function (val, oldVal) {
        oldVal;
        if (val.href == "/") {
          this.head = 0;
        }
      },
      // 深度观察监听
      //deep: true,
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  font-size: 15px;
}

/* .header ul{
       background-color:rgb(218, 216, 216);
    } */
.header ul li {
  float: left;
  list-style: none;
  margin-left: 1.05%;
  color: rgb(248, 250, 252);
  height: 2.5em;
  line-height: 2.5em;
  width: 6%;
  border-radius: 10px;
  background-color: rgb(252, 63, 6);
}

.header ul li:hover {
  color: rgb(249, 251, 252);
  background-color: rgb(32, 8, 252);
}

.header .active {
  background-color: rgb(32, 8, 252);
}

.clear {
  clear: both;
}
</style>