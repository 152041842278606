
import { createStore } from 'vuex'
//import createPersistedState from "vuex-persistedstate"
export default createStore({

  state: {  //数据状态管理
    lottey:[],
    ssq: [],
    klb: [],
    fc3d: [],
    qlc: [],
    dlt: [],
    pl35: [],
    qxc: [],
    header:[],
    yilou: [],
  },

  mutations: {
    mutationssq(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.ssq.splice("");
      state.ssq = data;
    },
    mutationfc3d(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.fc3d.splice("");
      state.fc3d= data;
    },
    mutationklb(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.klb.splice("");
      state.klb= data;
    },
    mutationqlc(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.qlc.splice("");
      state.qlc= data;
    },
    mutationdlt(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.dlt.splice("");
      state.dlt= data;
    },
    mutationpl35(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.pl35.splice("");
      state.pl35= data;
    },
    mutationqxc(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.qxc.splice("");
      state.qxc= data;
    },
    mutationheader(state, data) {     //vuex中state属性   params：commit触发时，传递过来的数据
      state.header= data;
    },
  },

  actions: {//actions中可以进行异步操作
    actionssq({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationssq", params)   //通过commit调用 mutations里的方法
    },
    actionfc3d({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationfc3d", params)   //通过commit调用 mutations里的方法
    },
    actionklb({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationklb", params)   //通过commit调用 mutations里的方法
    },
    actionqlc({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationqlc", params)   //通过commit调用 mutations里的方法
    },
    actiondlt({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationdlt", params)   //通过commit调用 mutations里的方法
    },
    actionpl35({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationpl35", params)   //通过commit调用 mutations里的方法
    },
    actionqxc({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationqxc", params)   //通过commit调用 mutations里的方法
    },
    actionheader({ commit }, params) {     //第一个参数是个对象，解构赋值拿到对象中的commit；第二个参数是传递给mutations的参数
      commit("mutationheader", params)   //通过commit调用 mutations里的方法
    },
  },
  modules: {

  },
  getters: {//getters只对state中的数据进行包装重新返回，不会修改state中的数据

  },
})
