<template>
    <div class="top">
       
        <div class="a0">
           <img src="../assets/png/1.png" alt="">
        </div>
        <div class="a1">
            <!-- <img src="../assets/gif/00.gif" alt=""> -->
        </div>
        <div class="a2">
          
        </div>

    </div>
</template>

<script>
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: '',
        components: {

        },
        data() { // 数据源
            return {
            }
        },
        mounted() {

        },
        computed: {
        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: {
        }
    }
</script>

<style scoped>
    .top {
        width: 100%;
        height: 9.5em;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .a0 {
        width: 90px;
        height: 90px;
        float: left;
        margin-top:5px;
        margin-left:50px;
    }

    .a1 {
        width: 100%;
        height: 100%;
        background: url("../assets/jpg/top.jpg");
        background-size: 100%;
        height: 100px;

    }
   
</style>