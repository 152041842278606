import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from "mitt"
import VueLazyload from 'vue-lazyload'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//import axios from 'axios'
//createApp(App).use(store).use(router).use(mitt).use(VueLazyload).use(ElementPlus).mount('#app')
const app = createApp(App)
app.use(store)
app.use(router)
app.use(mitt)
app.use(VueLazyload)
app.use(ElementPlus)
app.mount('#app')






// //-----------------------------路由守卫------------------------------------------------------
// router.beforeEach((to, from, next) => {
//     iView.LoadingBar.start();
//     //console.log("store",store);
//     //处理刷新界面动态路由丢失问题
//     let userToken = store.state.users.currentUser.UserToken;
//     if (userToken) {
//       //动态数组丢失说明用户刷新了页面
//       if (store.state.users.routers.length == 0) {
//         //从localStorage中获取菜单树。然后重新加载路由
//         let menuRoutes = [];
//         menuRoutes = util.initMenu(menuRoutes, JSON.parse(localStorage.getItem("menuData")));
//         store.commit('users/updateRouter', menuRoutes);
//         //确保addRoutes()时动态添加的路由已经被完全加载上去。
//         next({ ...to, replace: true })
//       } else {
//         next();
//       }
//     } else {
//       next();
//     }
//   });